
//login
import { login, loginResponse, loginClear, AuthCheck, refreshToken } from "./action/Login"
//dashboard
import { getAddPatientDetail, changeAddpatientDetailFilters } from "./action/addpatient"
import { getViewMedpresDetail } from "./action/viewmedicineprescription"


export const actionsApi = {
    login, 
    loginResponse, 
    loginClear, 
    AuthCheck, 
    refreshToken,
    //addpatient 
    getAddPatientDetail, 
    changeAddpatientDetailFilters,

    // service patient by id
    getViewMedpresDetail,
}
