import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	addpatient,
	viewmedicineprescription,
} from "./action"
const store = configureStore({
	reducer: {
		login,
		addpatient,
		viewmedicineprescription,
	},
})
export default store