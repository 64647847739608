import React, {Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes as Switch} from 'react-router-dom'

// import {actionsApi} from "../shared";
import { NewCredential, Otps, SendEmail, SuccessScreen } from '../components/index.jsx'
import Protected from './Protected'
// import { useDispatch, useSelector } from 'react-redux'
const Entry = lazy(() => import('../pages/Sidebar/index.jsx'))

const  RouteF = () => {
    // const {data} = useSelector(state => state?.login)    
    // const appDispatcher=useDispatch()
    const [ otpid, setOtpId ] = useState(null)
    const [token, setToken]= useState(null)
    useEffect(()=>{
        const tokenString = localStorage.getItem('token')
      //  if(tokenString)
      //   appDispatcher(actionsApi?.AuthCheck())
    },[])
    // useState(()=>{
    //     // if(data)
    //     // {
    //         const tokenString = localStorage.getItem('token');
    //       //  setToken(JSON.parse(tokenString))
    //     // }
    // },[data])
 

       return (
          <Switch>
              <Route 
                  path="/*" 
                  element={
                      <Protected>
                          <Entry />
                      </Protected>
                  }
              />
              <Route path="/sendemail" element={<SendEmail />} />
              <Route path="/otp" element={<Otps setOtpId={setOtpId} />} />
              <Route path="/newcredential" element={<NewCredential otpid={otpid} />} />
              <Route path="/successscreen" element={<SuccessScreen />} />
          </Switch>
       )
}

export default RouteF