import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const updateCreateApi = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    myHeaders.append("Content-Type", "application/json",);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return(
        fetch(domainUrl + '/storeAideInstruction', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } else {
                message.error(result?.message);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};
  

const patientprescriptionApi = {
    updateCreateApi,
}

export {patientprescriptionApi}
