import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddPatientDetail = createAsyncThunk('getPatientStatistics',
    async (object, { dispatch, getState }) => {

        const { userToken } = checkAuthorization()
        dispatch(gettingAddPatientDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ title: object?.title, order: object?.order, name: object?.name })
        };
        fetch(domainUrl + `/viewAidePatients`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addpatientDetailResponse(result?.data))
                    dispatch(setPages({ pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords }))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(addpatientDetailClear())
                message.error(error)
            })
    }
)


const AddPatientSlice = createSlice({
    name: 'addpatient',
    initialState: {
        data: null,
        loading: false,
        addpatientDetail: null,
        loadingAddPatientDetail: false,
        pagination: {
            pageNo: 1,
            pageSize: 10,
            totalRecords: 0,
        },
        filters: {
            title: '',
            order: 1,
            id: '',
            status: '',
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading = true
            state.data = null
        },
        statisticsResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        statisticsClear: (state) => {
            state.data = null
            state.loading = false
        },
        gettingAddPatientDetail: state => {
            state.loadingAddPatientDetail = true
            state.addpatientDetail = null
        },
        addpatientDetailResponse: (state, action) => {
            state.addpatientDetail = action.payload
            state.loadingAddPatientDetail = false
        },
        addpatientDetailClear: (state) => {
            state.addpatientDetail = null
            state.loadingAddPatientDetail = false
        },
        changeAddpatientDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages: (state, action) => {
            state.pagination = action.payload
        },
    },
})

export const {
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingAddPatientDetail, addpatientDetailResponse, addpatientDetailClear,
    changeAddpatientDetailFilters, setPages
} = AddPatientSlice.actions;
export default AddPatientSlice.reducer;
